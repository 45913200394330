#root .tablet {
  display: none;
}
#root .contact-page .background {
  padding-top: 259px;
  z-index: 10;
  position: relative;
  width: 100%;
  height: 497px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url(/public/assets/contact/pc/contact-img.png);
  background-image: -webkit-image-set(url(/public/assets/contact/pc/contact-img.png) 1x, url(/public/assets/contact/pc/contact-img@2x.png) 2x, url(/public/assets/contact/pc/contact-img@3x.png) 3x);
}
#root .contact-page .background .title {
  font-weight: 700;
  font-size: 100px;
}
#root .contact-page .contact {
  padding-top: 92px;
  width: 1104px;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#root .contact-page .contact .left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 48px;
}
#root .contact-page .contact .left .content > .title {
  font-weight: 700;
  font-size: 30px;
  margin-bottom: 15px;
}
#root .contact-page .contact .left .content > .text {
  font-weight: 600;
  font-size: 20px;
}
#root .contact-page .contact .right {
  width: 541px;
  height: 301px;
}
#root .contact-page .contact .right .map {
  width: 541px;
  height: 301px;
}

@media screen and (max-width: 1439px) {
  #root .tablet {
    display: block;
  }
  #root .contact-page .background {
    padding-top: 225px;
    height: 357px;
    background-image: url(/public/assets/contact/tablet/contact-img.png);
    background-image: -webkit-image-set(url(/public/assets/contact/tablet/contact-img.png) 1x, url(/public/assets/contact/tablet/contact-img@2x.png) 2x, url(/public/assets/contact/tablet/contact-img@3x.png) 3x);
  }
  #root .contact-page .background .title {
    font-size: 60px;
  }
  #root .contact-page .contact {
    padding-top: 84px;
    width: 688px;
  }
  #root .contact-page .contact .left .content > .title {
    font-size: 26px;
    margin-bottom: 10px;
  }
  #root .contact-page .contact .left .content > .text {
    font-size: 18px;
  }
  #root .contact-page .contact .right {
    width: 375px;
    height: 301px;
  }
  #root .contact-page .contact .right .map {
    width: 375px;
    height: 301px;
  }
}
@media screen and (max-width: 767px) {
  #root .contact-page {
    padding-bottom: 210px;
  }
  #root .contact-page .background {
    padding-top: 225px;
    height: 357px;
    background-image: url(/public/assets/contact/mb/contact-img.png);
    background-image: -webkit-image-set(url(/public/assets/contact/mb/contact-img.png) 1x, url(/public/assets/contact/mb/contact-img@2x.png) 2x, url(/public/assets/contact/mb/contact-img@3x.png) 3x);
  }
  #root .contact-page .background .title {
    font-size: 50px;
  }
  #root .contact-page .contact {
    padding-top: 41px;
    width: 328px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  #root .contact-page .contact .left {
    text-align: center;
    margin-bottom: 53px;
  }
  #root .contact-page .contact .right {
    width: 328px;
  }
  #root .contact-page .contact .right .map {
    width: 328px;
  }
}