#root .section1 {
  margin: 0 auto;
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
#root .section1 .section1-img {
  position: absolute;
  top: 0;
  width: 100%;
}
#root .section1 .section1-title-wrapper {
  position: relative;
  z-index: 1;
  color: white;
}
#root .section1 .section1-title-wrapper .section1-title {
  font-weight: bold;
}
#root .section1 .section1-title-wrapper .section1-sub {
  line-height: 150%;
  font-weight: bold;
  margin-top: 10px;
}

@media screen and (min-width: 1440px) {
  #root .section1 {
    max-width: 1440px;
    height: 883px;
    padding: 273px 56px 0 56px;
  }
  #root .section1 .section1-title-wrapper .section1-title {
    font-size: 70px;
  }
  #root .section1 .section1-title-wrapper .section1-sub {
    font-size: 28px;
  }
}
@media screen and (max-width: 1439px) and (min-width: 768px) {
  #root .section1 {
    max-width: 768px;
    height: 454px;
    padding: 0 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  #root .section1 .section1-title-wrapper .section1-title {
    font-size: 48px;
  }
  #root .section1 .section1-title-wrapper .section1-sub {
    font-size: 18px;
  }
}
@media screen and (max-width: 767px) {
  #root .section1 {
    height: 620px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
  }
  #root .section1 .section1-img {
    width: 768px;
  }
  #root .section1 .section1-title-wrapper {
    text-align: center;
  }
  #root .section1 .section1-title-wrapper .section1-title {
    font-size: 32px;
  }
  #root .section1 .section1-title-wrapper .section1-sub {
    font-size: 18px;
  }
}