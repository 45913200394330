@charset "UTF-8";
main {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  min-height: 100%;
  color: white;
}

@font-face {
  font-family: "gemenu";
  font-weight: 700;
  font-display: swap;
  src: local("GemunuLibre Bold"), url("./woff/stratum2-bold-webfont.ttf") format("woff");
}
@font-face {
  font-family: "gmarket";
  font-weight: 700;
  font-display: swap;
  src: local("Gmarket Bold"), url("./woff/GmarketSansTTFBold.ttf") format("woff");
}
.font {
  font-family: "gemenu", sans-serif !important;
  font-weight: 700;
  font-style: normal;
}

.gmarket {
  font-family: "gmarket", sans-serif !important;
  font-weight: 700;
  font-style: normal;
}

html,
body {
  background-color: black;
  width: 100%;
  height: 100%;
  font-family: "pretendard", sans-serif;
  /* 세로 스크롤 활성화 */
  /* 가로 스크롤 비활성화 */
}

#root {
  width: 100%;
  height: 100%;
  background-color: black;
  overflow-x: hidden;
}
#root font {
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
      -ms-text-size-adjust: none;
          text-size-adjust: none;
}
#root font[size="1"] {
  font-size: 13px;
}
#root font[size="2"] {
  font-size: 15px;
}
#root font[size="3"] {
  font-size: 17px;
}
#root font[size="4"] {
  font-size: 19px;
}
#root font[size="5"] {
  font-size: 21px;
}
#root font[size="6"] {
  font-size: 23px;
}
#root font[size="7"] {
  font-size: 25px;
}

* {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  border: none;
  scrollbar-width: none;
  -webkit-font-smoothing: antialiased;
  -ms-overflow-style: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: "pretendard", sans-serif;
  --gray500: #54585A;
  --gray600: #242627;
  --blue: #4776E6;
}

p {
  padding: 0;
}

body::-webkit-scrollbar {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

button,
a {
  cursor: pointer;
  color: black;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

button:hover,
a:hover {
  opacity: 0.7;
}

a {
  text-decoration: none;
}

img {
  width: 100%;
}

input:focus {
  outline: none;
}

textarea:focus {
  outline: none;
}

.none {
  display: none !important;
}

input,
p,
textarea {
  resize: none;
  font-family: "pretendard", sans-serif;
  padding: 16px;
}
input::-webkit-input-placeholder, p::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  font-weight: 400;
  font-size: 16px;
  font-family: "pretendard", sans-serif;
  color: #949494;
}
input::-moz-placeholder, p::-moz-placeholder, textarea::-moz-placeholder {
  font-weight: 400;
  font-size: 16px;
  font-family: "pretendard", sans-serif;
  color: #949494;
}
input:-ms-input-placeholder, p:-ms-input-placeholder, textarea:-ms-input-placeholder {
  font-weight: 400;
  font-size: 16px;
  font-family: "pretendard", sans-serif;
  color: #949494;
}
input::-ms-input-placeholder, p::-ms-input-placeholder, textarea::-ms-input-placeholder {
  font-weight: 400;
  font-size: 16px;
  font-family: "pretendard", sans-serif;
  color: #949494;
}
input::placeholder,
p::placeholder,
textarea::placeholder {
  font-weight: 400;
  font-size: 16px;
  font-family: "pretendard", sans-serif;
  color: #949494;
}
input:focus,
p:focus,
textarea:focus {
  outline: none;
}