#root .section6 > .wrapper {
  width: 1440px;
  padding: 0px 24px;
  margin: 0 auto;
}
#root .section6 > .wrapper > .sub-img-wrapper {
  width: 1440px;
  margin: 0 auto;
}
#root .section6 > .wrapper > .section6-text-wrapper {
  width: 1440px;
  margin: 0 auto;
}
#root .section6 > .wrapper > .section6-text-wrapper p {
  padding: 0;
}
#root .section6 > .wrapper > .section6-text-wrapper > .title {
  text-transform: uppercase;
  color: var(--blue);
  font-weight: 700;
  font-size: 48px;
  margin-bottom: 11px;
}
#root .section6 > .wrapper > .section6-text-wrapper > .subtitle-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 8px;
}
#root .section6 > .wrapper > .section6-text-wrapper > .subtitle-wrapper p {
  font-size: 40px;
}
#root .section6 > .wrapper > .section6-text-wrapper > .subtitle-wrapper > .subtitle-bold {
  font-weight: 700;
}
#root .section6 > .wrapper > .section6-text-wrapper > .subtitle-wrapper .subtitle {
  font-weight: 400;
}
#root .section6 > .wrapper > .line {
  width: 1440px;
  margin: 0 auto;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.3);
  margin-top: 38px;
  margin-bottom: 34px;
}
#root .section6 > .wrapper > .history-wrapper {
  width: 1440px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 76px;
  position: relative;
  margin: 0 auto;
  margin-bottom: 305px;
}
#root .section6 > .wrapper > .history-wrapper > .history-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 40px;
     -moz-column-gap: 40px;
          column-gap: 40px;
}
#root .section6 > .wrapper > .history-wrapper > .history-box > .circle {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 40px;
  font-weight: 700;
  z-index: 1;
  background-color: #3E52E9;
  -webkit-box-shadow: 0 0 60px rgb(62, 82, 233), 0 0 300px rgb(62, 82, 233);
          box-shadow: 0 0 60px rgb(62, 82, 233), 0 0 300px rgb(62, 82, 233);
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
#root .section6 > .wrapper > .history-wrapper > .history-box > .history-lists {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  row-gap: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
#root .section6 > .wrapper > .history-wrapper > .history-box > .history-lists > .history-list {
  font-size: 18px;
  list-style-type: disc;
}
#root .section6 > .wrapper > .history-wrapper .history-box:nth-child(2) {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: start;
}
#root .section6 > .wrapper > .history-wrapper .line {
  width: 1px;
  height: 100%;
  position: absolute;
  background-color: var(--blue);
  left: 70px;
}
#root .section6 > .contactus-wrapper {
  width: 100%;
  height: 432px;
  padding: 66px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-column-gap: 180px;
     -moz-column-gap: 180px;
          column-gap: 180px;
}
#root .section6 > .contactus-wrapper > .contactus-title {
  text-transform: uppercase;
  font-size: 70px;
  font-weight: 700;
}
#root .section6 > .contactus-wrapper > .info-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 50px;
}
#root .section6 > .contactus-wrapper > .info-wrapper > .info-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 5px;
}
#root .section6 > .contactus-wrapper > .info-wrapper > .info-box > .info-title {
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 700;
}
#root .section6 > .contactus-wrapper > .info-wrapper > .info-box > .info-subtitle {
  font-size: 20px;
  font-weight: 400;
}

@media screen and (max-width: 1440px) {
  #root .section6 .wrapper {
    padding: 0px 60px;
  }
}
@media screen and (max-width: 1439px) and (min-width: 768px) {
  #root .section6 .wrapper {
    padding: 0;
    width: auto;
  }
  #root .section6 .wrapper .sub-img-wrapper {
    width: auto;
  }
  #root .section6 .wrapper .section6-text-wrapper {
    width: auto;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  #root .section6 .wrapper .section6-text-wrapper .title {
    font-size: 32px;
    text-align: center;
  }
  #root .section6 .wrapper .section6-text-wrapper .subtitle-wrapper {
    text-align: center;
  }
  #root .section6 .wrapper .section6-text-wrapper .subtitle-wrapper p {
    font-size: 32px;
  }
  #root .section6 .wrapper > .history-wrapper {
    width: 728px;
    margin-top: 40px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  #root .section6 .wrapper > .history-wrapper .history-box {
    width: 100%;
  }
  #root .section6 .wrapper > .history-wrapper .history-box .circle {
    width: 120px;
    height: 120px;
  }
  #root .section6 .wrapper > .history-wrapper .line {
    left: 60px;
  }
  #root .section6 .contactus-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  #root .section6 .contactus-wrapper .contactus-title {
    font-size: 50px;
  }
  #root .section6 .contactus-wrapper .info-wrapper {
    margin-top: 24px;
    text-align: center;
  }
  #root .section6 .contactus-wrapper .info-wrapper .info-box .info-title {
    font-size: 24px;
  }
  #root .section6 .contactus-wrapper .info-wrapper .info-box .info-subtitle {
    font-size: 18px;
  }
}
@media screen and (max-width: 767px) {
  #root .section6 {
    padding: 0px;
  }
  #root .section6 .wrapper {
    width: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 0;
  }
  #root .section6 .wrapper .sub-img-wrapper {
    width: auto;
  }
  #root .section6 .wrapper > .section6-text-wrapper {
    width: auto;
  }
  #root .section6 .wrapper > .section6-text-wrapper > .title {
    text-align: center;
    font-size: 24px;
  }
  #root .section6 .wrapper > .section6-text-wrapper > .subtitle-wrapper {
    text-align: center;
  }
  #root .section6 .wrapper > .section6-text-wrapper > .subtitle-wrapper p {
    font-size: 23px;
  }
  #root .section6 .wrapper > .history-wrapper {
    width: 400px;
  }
  #root .section6 .wrapper > .history-wrapper .line {
    top: 100px;
    left: 44px;
  }
  #root .section6 .wrapper > .history-wrapper > .history-box:nth-child(1) {
    height: 150px;
  }
  #root .section6 .wrapper > .history-wrapper > .history-box:nth-child(2) {
    height: 350px;
  }
  #root .section6 .wrapper > .history-wrapper > .history-box {
    margin-top: 24px;
    -webkit-column-gap: 0;
       -moz-column-gap: 0;
            column-gap: 0;
  }
  #root .section6 .wrapper > .history-wrapper > .history-box > .history-lists {
    position: relative;
    top: 105px;
    left: -10px;
  }
  #root .section6 .wrapper > .history-wrapper > .history-box > .history-lists > .history-list {
    white-space: pre-wrap;
    line-height: 150%;
    font-size: 13px;
    width: 270px;
  }
  #root .section6 .wrapper > .history-wrapper > .history-box > .circle {
    width: 90px;
    height: 90px;
    font-size: 20px;
  }
  #root .section6 .contactus-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  #root .section6 .contactus-wrapper .contactus-title {
    font-size: 32px;
  }
  #root .section6 .contactus-wrapper .info-wrapper {
    margin-top: 24px;
    text-align: center;
  }
  #root .section6 .contactus-wrapper .info-wrapper .info-box .info-title {
    font-size: 18px;
  }
  #root .section6 .contactus-wrapper .info-wrapper .info-box .info-subtitle {
    white-space: pre-wrap;
    line-height: 150%;
    font-size: 14px;
  }
}