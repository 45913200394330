#root .section5 {
  width: 1440px;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0px 56px;
  margin-bottom: 201px;
}
#root .section5 > .section5-title-wrapper {
  width: 100%;
  margin-left: 0 auto;
  margin-bottom: 32px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
#root .section5 > .section5-title-wrapper > .title-btn {
  margin-bottom: 24px;
  padding: 10px 12px;
  background: var(--blue);
  font-size: 20px;
  color: white;
  font-weight: 700;
  border-radius: 20px;
}
#root .section5 > .section5-title-wrapper > .title {
  font-size: 40px;
  line-height: 160%;
}
#root .section5 > .section5-title-wrapper > .title span {
  font-weight: 700;
}
#root .section5 > .section5-title-wrapper > .title:nth-child(3) {
  font-weight: 700;
}
#root .section5 > .section5-title-wrapper > .subtitle-wrapper span {
  display: block;
  font-weight: 700;
  line-height: 150%;
  margin-top: 16px;
  margin-bottom: 49px;
  white-space: pre-wrap;
  color: var(--blue);
}
#root .section5 > .section5-card-wrapper {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 16px;
     -moz-column-gap: 16px;
          column-gap: 16px;
}
#root .section5 > .section5-card-wrapper > .left-card, #root .section5 > .section5-card-wrapper > .right-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 16px;
}
#root .section5 > .section5-card-wrapper > .right-card {
  position: relative;
  top: 150px;
}

@media screen and (max-width: 1439px) and (min-width: 768px) {
  #root .section5 {
    width: 100%;
  }
  #root .section5 > .section5-title-wrapper {
    width: 728px;
    margin: 0 auto;
  }
  #root .section5 > .section5-title-wrapper > .title-btn {
    font-size: 16px;
  }
  #root .section5 > .section5-title-wrapper > .title {
    font-size: 34px;
  }
  #root .section5 > .section5-card-wrapper {
    width: 728px;
    margin-top: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    row-gap: 16px;
  }
  #root .section5 > .section5-card-wrapper > .left-card .card, #root .section5 > .section5-card-wrapper > .right-card .card {
    width: 728px;
  }
  #root .section5 > .section5-card-wrapper > .right-card {
    top: 0;
  }
}
@media screen and (max-width: 767px) {
  #root .section5 {
    width: 100%;
    padding: 0px 20px;
  }
  #root .section5 .section5-title-wrapper .title {
    font-size: 24px;
  }
  #root .section5 .section5-title-wrapper .title-btn {
    margin-bottom: 24px;
    padding: 10px 12px;
    background: var(--blue);
    font-size: 14px;
    color: white;
    font-weight: 700;
    border-radius: 20px;
  }
  #root .section5 > .section5-card-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    row-gap: 16px;
  }
  #root .section5 > .section5-card-wrapper > .right-card {
    top: 0;
  }
}