#root .card {
  width: 656px;
  padding: 24px;
  border-radius: 5px;
  background-color: #282828;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
#root .card .sub-img {
  width: 60px;
  height: 60px;
}
#root .card > .card-title-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 7px;
}
#root .card > .card-title-wrapper span {
  display: block;
  white-space: pre-wrap;
  line-height: 150%;
}
#root .card > .card-title-wrapper > .card-title {
  font-size: 24px;
  font-weight: 700;
}
#root .card > .card-title-wrapper > .card-subtitle {
  font-size: 14px;
  font-weight: 400;
}
#root .card > .img-wrapper {
  margin-left: auto;
}

@media screen and (max-width: 767px) {
  #root .card {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  #root .card > .img-wrapper {
    margin-top: 80px;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  #root .card > .card-title-wrapper > .card-title {
    font-size: 20px;
  }
  #root .card > .card-title-wrapper > .card-subtitle {
    font-size: 13px;
  }
  #root .card .img-wrapper {
    width: auto;
  }
}