#root .section4 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: auto;
  margin-bottom: 250px;
}
#root .section4 > .img-wrapper {
  width: 1961px;
  overflow-y: hidden;
}
#root .section4 > .img-wrapper img {
  width: 100%;
}
#root .section4 > .section4-title-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  row-gap: 8px;
  z-index: 1;
  margin-top: -50px;
}
#root .section4 > .section4-title-wrapper > .title {
  text-transform: uppercase;
  white-space: pre;
  line-height: 150%;
  font-size: 40px;
  font-weight: 700;
  text-align: center;
}
#root .section4 > .section4-title-wrapper > .subtitle {
  text-align: center;
  font-size: 32px;
  font-weight: 400;
  margin-bottom: 16px;
}
#root .section4 > .section4-title-wrapper > .application-btn {
  width: 269px;
  border-radius: 5px;
  background: var(--blue);
  height: 50px;
}
#root .section4 > .section4-title-wrapper > .application-btn a {
  font-size: 20px;
  font-weight: 700;
  color: white;
}

@media screen and (max-width: 1439px) and (min-width: 768px) {
  #root .section4 > .img-wrapper {
    width: 1440px;
  }
  #root .section4 > .img-wrapper img {
    width: 100%;
  }
  #root .section4 > .section4-title-wrapper {
    top: -20px;
  }
  #root .section4 > .section4-title-wrapper > .title {
    font-size: 34px;
  }
  #root .section4 > .section4-title-wrapper > .subtitle {
    font-size: 28px;
  }
  #root .section4 > .section4-title-wrapper > .application-btn a {
    font-size: 18px;
  }
}
@media screen and (max-width: 767px) {
  #root .section4 {
    width: auto;
  }
  #root .section4 > .img-wrapper {
    width: 768px;
  }
  #root .section4 > .img-wrapper img {
    width: 100%;
  }
  #root .section4 .section4-title-wrapper {
    margin-top: 20px;
  }
  #root .section4 .section4-title-wrapper .title {
    font-size: 24px;
  }
  #root .section4 .section4-title-wrapper .subtitle {
    font-size: 24px;
  }
  #root .section4 .section4-title-wrapper .application-btn a {
    font-size: 18px;
  }
  #root .section4 .section4-title-wrapper {
    top: -30px;
  }
}