#root .section3 {
  margin-top: 200px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .section3 .section3-text-wrapper {
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 62px;
}
#root .section3 .section3-text-wrapper .section3-tag {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: var(--blue);
  border-radius: 50px;
  padding: 10px 12px;
  font-size: 20px;
  font-weight: bold;
}
#root .section3 .section3-text-wrapper .section3-title {
  font-size: 32px;
  font-weight: 400;
  line-height: 150%;
  margin: 22px 0px;
}
#root .section3 .section3-text-wrapper .section3-title > .text-bold {
  font-weight: 700;
}
#root .section3 .section3-text-wrapper .section3-sub {
  line-height: 150%;
  white-space: pre-line;
}
#root .section3 .section3-box {
  width: 1295px;
  padding: 30px 0px;
  padding-bottom: 62px;
  background-color: #1e1e1e;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .section3 .section3-box > .section3-box-title {
  font-size: 24px;
}
#root .section3 .section3-box > .box-select-list {
  width: 812px;
  margin-top: 22px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 16px;
}
#root .section3 .section3-box > .box-select-list > .box-select-btn {
  border: 1px solid white;
  border-radius: 20px;
  color: white;
  background-color: black;
  padding: 12px 10px;
  font-weight: 700;
  white-space: nowrap;
}
#root .section3 .section3-box > .box-select-list > .active {
  background-color: var(--blue);
  border: 1px solid var(--blue);
}
#root .section3 .section3-box > .education-wrapper {
  width: 880px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 52px;
}
#root .section3 .section3-box > .education-wrapper > .education-text-wrapper {
  width: 100%;
  padding: 24px;
  background-color: white;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  color: black;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
#root .section3 .section3-box > .education-wrapper > .education-text-wrapper > .education-title {
  margin-bottom: 8px;
  font-weight: bold;
  font-size: 30px;
}
#root .section3 .section3-box > .education-wrapper > .education-text-wrapper > .education-subtitle {
  white-space: pre-wrap;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
}
#root .section3 .section3-box > .education-wrapper > .education-img {
  width: 100%;
}
#root .section3 .section3-box > .education-wrapper > .education-img img {
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

@media screen and (max-width: 1440px) {
  #root .section3 {
    margin-top: 200px;
  }
  #root .section3 .section3-text-wrapper {
    row-gap: 24px;
  }
  #root .section3 .section3-text-wrapper .section3-title {
    font-size: 40px;
  }
  #root .section3 .section3-text-wrapper .section3-sub {
    font-size: 18px;
  }
  #root .section3 .section3-box {
    padding: 35px 0px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  #root .section3 .section3-box > .education-wrapper > .education-text-wrapper {
    padding: 20px;
  }
  #root .section3 .section3-box > .box-select-list {
    width: 710px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  #root .section3 .section3-box > .box-select-list > .box-select-btn {
    font-size: 14px;
  }
}
@media screen and (max-width: 1439px) and (min-width: 768px) {
  #root .section3 {
    margin-top: 100px;
  }
  #root .section3 > .section3-box > .education-wrapper {
    width: 320px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  #root .section3 > .section3-box > .education-wrapper > .education-text-wrapper {
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
  }
  #root .section3 > .section3-box > .education-wrapper > .education-img img {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  #root .section3 .section3-text-wrapper {
    row-gap: 16px;
  }
  #root .section3 .section3-text-wrapper .section3-tag {
    font-size: 16px;
  }
  #root .section3 .section3-text-wrapper .section3-title {
    font-size: 34px;
  }
  #root .section3 .section3-text-wrapper .section3-sub {
    font-size: 16px;
  }
}
@media screen and (max-width: 767px) {
  #root .section3 {
    margin-top: 200px;
  }
  #root .section3 > .section3-box {
    width: 100%;
  }
  #root .section3 > .section3-box > .box-select-list {
    width: 372px;
    row-gap: 16px;
    -webkit-column-gap: 8px;
       -moz-column-gap: 8px;
            column-gap: 8px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  #root .section3 > .section3-box > .box-select-list > .box-select-btn {
    padding: 10px 12px;
    font-size: 13px;
  }
  #root .section3 > .section3-box > .education-wrapper {
    width: 320px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  #root .section3 > .section3-box > .education-wrapper > .education-text-wrapper {
    border-top-right-radius: 5px;
    border-bottom-left-radius: 0;
  }
  #root .section3 > .section3-box > .education-wrapper > .education-img img {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  #root .section3 .section3-text-wrapper {
    row-gap: 8px;
  }
  #root .section3 .section3-text-wrapper .section3-tag {
    font-size: 14px;
  }
  #root .section3 .section3-text-wrapper .section3-title {
    font-size: 24px;
  }
  #root .section3 .section3-text-wrapper .section3-sub {
    font-size: 14px;
  }
}