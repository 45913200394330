#root header {
  width: 100%;
  height: 50px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
  background-color: transparent;
  padding: 0 56px;
}
#root header > .wrapper {
  height: 100%;
  max-width: 1440px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0 auto;
}
#root header > .wrapper .right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
#root header > .wrapper .right .nav-btn {
  width: 154px;
  height: 44px;
  border-radius: 50px;
  background-color: var(--blue);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 16px;
  color: white;
}
#root header > .wrapper .right .lang-wrapper {
  position: relative;
  min-width: 80px;
}
#root header > .wrapper .right .lang-wrapper .lang-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 2px;
     -moz-column-gap: 2px;
          column-gap: 2px;
  background-color: transparent;
  padding: 10px 1px 10px 16px;
  color: white;
  font-size: 18px;
  font-weight: bold;
}
#root header > .wrapper .right .lang-wrapper .lang-select-wrapper {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  position: absolute;
  top: 100%;
  background-color: var(--gray500);
  overflow: hidden;
  row-gap: 1px;
  border-radius: 5px;
  height: 0;
  -webkit-transition: height 0.2s ease-in-out;
  transition: height 0.2s ease-in-out;
  width: 80px;
}
#root header > .wrapper .right .lang-wrapper .lang-select-wrapper .lang-select-btn {
  background-color: var(--gray600);
  color: white;
  font-size: 13px;
  font-weight: bold;
}
#root header > .wrapper .right .lang-wrapper .open-select {
  height: 58px;
}

@media screen and (min-width: 1440px) {
  #root header {
    height: 70px;
  }
}
@media screen and (max-width: 1439px) and (min-width: 768px) {
  #root header {
    height: 50px;
    padding: 0 20px;
  }
  #root header > .wrapper .header-logo {
    width: 127px;
  }
}
@media screen and (max-width: 767px) {
  #root header {
    height: 50px;
    padding: 0 20px;
  }
  #root header > .wrapper .header-logo {
    width: 127px;
  }
}