#root .section2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .section2 .gradient-line {
  width: 1px;
  height: 295px;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(20%, #000), to(#FFF));
  background: linear-gradient(to bottom, #000 20%, #FFF);
}
#root .section2 .section2-text-wrapper {
  display: grid;
  text-align: center;
}
#root .section2 .section2-text-wrapper .section2-text-color {
  color: var(--blue);
}
#root .section2 .section2-text-wrapper .section2-text-title {
  line-height: 150%;
}
#root .section2 .section2-text-wrapper .section2-text-sub {
  line-height: 150%;
}

@media screen and (min-width: 1440px) {
  #root .section2 .section2-text-wrapper {
    row-gap: 16px;
    margin: 24px 0;
  }
  #root .section2 .section2-text-wrapper .section2-text-color {
    font-size: 48px;
  }
  #root .section2 .section2-text-wrapper .section2-text-title {
    font-size: 40px;
  }
  #root .section2 .section2-text-wrapper .section2-text-sub {
    font-size: 18px;
  }
  #root .section2 .section2-img {
    width: 1083px;
    -webkit-transform: translateY(-8%);
            transform: translateY(-8%);
  }
}
@media screen and (max-width: 1439px) and (min-width: 768px) {
  #root .section2 .section2-text-wrapper {
    row-gap: 16px;
    margin: 24px 0;
  }
  #root .section2 .section2-text-wrapper .section2-text-color {
    font-size: 32px;
  }
  #root .section2 .section2-text-wrapper .section2-text-title {
    font-size: 34px;
  }
  #root .section2 .section2-text-wrapper .section2-text-sub {
    font-size: 16px;
  }
  #root .section2 .section2-img {
    width: 768px;
    -webkit-transform: translateY(-20%);
            transform: translateY(-20%);
  }
}
@media screen and (max-width: 767px) {
  #root .section2 .section2-text-wrapper {
    row-gap: 8px;
    margin: 24px 0;
  }
  #root .section2 .section2-text-wrapper .section2-text-color {
    font-size: 24px;
  }
  #root .section2 .section2-text-wrapper .section2-text-title {
    font-size: 24px;
  }
  #root .section2 .section2-text-wrapper .section2-text-sub {
    font-size: 14px;
    white-space: pre-line;
  }
  #root .section2 .section2-img {
    width: 768px;
    -webkit-transform: translateY(-20%);
            transform: translateY(-20%);
  }
}